<template>
    <div class="wallet">
        <Logo />
        <div class="box balance">
            <div class="lable flex">
                <span>{{$t('wallet.amountLabel')}}</span>
                <!-- <a-icon @click="goWithdrawls" type="file-text" /> -->
            </div>
            <div class="amount color-primary">
                {{(userinfo.balances.bt+userinfo.balances.locked_bt+userinfo.balances.freeze_bt)/1000000}} {{$t('common.currency')}} <br/> {{(userinfo.balances.gfd/1000000)}} GFD
            </div>
            <div class="locked-amount">
                {{$t('wallet.lockedLabel')}} {{((userinfo.balances.locked_bt+userinfo.balances.freeze_bt)/1000000)}} {{$t('common.currency')}}
            </div>
            <div class="usds-amount">
                {{$t('wallet.released')}} {{(userinfo.balances.bt/1000000)}} {{$t('common.currency')}}
            </div>
            <div class="withdrawl flex">
                <div class="btn btn-primary" @click="ttransfer">
                    {{$t('wallet.transfer')}}
                </div>
                <div class="btn btn-primary-ghost" @click="withdrawl">
                    {{$t('wallet.withdrawl.btn')}}
                </div>
                <!-- <div class="btn btn-primary" @click="commingSoon">
                    {{$t('wallet.withdrawl.deposit')}}
                </div> -->
            </div>
        </div>
        <div v-if="showTotalFlow" class="box total">
            <div class="">
                <span>{{$t('wallet.totalFlow')}}</span>
            </div>
            <div class="amount">
                {{userinfo.total_flow/1000000}} {{$t('common.currency')}}
            </div>
            <div class="usdt-amount">
                &#8776; {{(userinfo.total_flow*usdt_rate/1000000).toFixed(6)}} USDT
            </div>
        </div>
        <!-- <div class="box total" @click="$router.push({name:'citizen'})">
            <div class="tag">
                {{$t('home.navTitles.tinjiku')}}
            </div>
            <div class="color-primary lable">
                {{$t('wallet.cumulativeLabel')}}
            </div>
            <div class="flex">
                <div>
                    <div class="amount">
                        {{(userinfo.citizen_std_total/1000000).toFixed(6)}} {{$t('common.currency')}}
                    </div>
                    <div class="usdt-amount">
                        {{$t('wallet.released')}} {{(userinfo.released_total/1000000).toFixed(6)}}
                    </div>
                </div>
                <a-progress type="circle" :width="80" strokeColor="#04cbaa"
                    :percent="Math.floor((userinfo.citizen_std_total - userinfo.released_total)*100/userinfo.citizen_std_total)"
                    status="active"
                />
            </div>
        </div> -->
        <div v-if="showGsil" class="box gsil">
            <div class=" title">
                <img src="@/assets/svgs/bless.svg" alt="">
                <span>{{$t('wallet.gsil.title')}}</span>
            </div>
            <div class="flex li">
                <div class="">
                    <span>{{$t('wallet.gsil.equity')}}</span>:{{ 0 }} {{$t('common.currency')}}
                </div>
                <a-icon type="plus-square" @click="$router.push({name:'swap'})" />
            </div>
            <!-- <div class="flex li">
                <div class="">
                    {{$t('wallet.gsil.lock')}}:{{0}} USDT
                </div>
                <a-icon @click="$router.push({name:'gsils'})" type="file-text" />
            </div> -->
        </div>
        <div v-if="showDefi" class="box defi">
            <div class="tag">
                {{$t('wallet.depositNodes')[userinfo.defi_level]}} 
            </div>
            <div>
                <span class="color-primary">{{$t('wallet.defiDeposit')}}</span>
            </div>
            <div class="flex">
                <div class="amount">
                    {{userinfo.defi_deposit_total/1000000}} {{$t('common.currency')}}
                </div>
                <div class="btn">
                    <a-icon @click="goDeposit" type="export" />
                </div>
            </div>
            <div class="flex">
                <div class="">
                    {{$t('wallet.listLabel.depositProfits')}}:{{userinfo.defi_deposit_profits_total/1000000}} {{$t('common.currency')}}
                </div>
                <a-icon @click="goProfit" type="file-text" />
            </div>
        </div>
        <!-- <div class="box defi">
            <div class="tag">
                {{$t('wallet.depositNodes')[userinfo.defi_level]}} 
            </div>
            <div>
                <span class="color-primary">{{$t('wallet.defiLock')}}</span>
            </div>
            <div class="flex">
                <div class="amount">
                    {{userinfo.defi_lock_total}} {{$t('common.currency')}}
                </div>
                <div class="btn">
                    <a-icon @click="goDefiLock" type="export" />
                </div>
            </div>
            <div class="flex">
                <div class="">
                    {{$t('wallet.listLabel.depositNodeProfit')}}:{{userinfo.defi_lock_profits_total/1000000}} {{$t('common.currency')}}
                </div>
                <a-icon @click="goDefiLockProfits" type="file-text" />
            </div>
        </div> -->
        
        <div v-if="list.length>0" class="box list">
            <div v-for="l,i in list" :key="'log-'+i" class="list-item">
                <div class="flex">
                    <div class="label">{{$t('wallet.typeText')[l.type]}}</div>
                    <div class="time color-gray">
                        {{l.created_at}}
                    </div>
                </div>
                <div class="amount">
                    <div v-if="l.bt!=0">
                        可用<span v-if="l.bt>0">+</span>{{ l.bt }}{{ $t('common.currency') }}
                    </div>
                    <!-- <span v-if="l.bt<0">{{ l.bt }}BT</span> -->
                    <div v-if="l.locked_bt!=0">
                        鎖倉<span v-if="l.locked_bt>0">+</span>{{ l.locked_bt }}{{ $t('common.currency') }}
                    </div>
                    <div v-if="l.freeze_bt!=0">
                        鎖倉<span v-if="l.freeze_bt>0">+</span>{{ l.freeze_bt }}{{ $t('common.currency') }}
                    </div>
                    <!-- <span v-if="l.locked_bt<0">{{ l.locked_bt }}BT</span> -->
                    <div v-if="l.gfd!=0">
                        善果<span v-if="l.gfd>0">+</span>{{ l.gfd }}GFD
                    </div>
                    <!-- <span v-if="l.gfd<0">{{ l.gfd }}GFD</span> -->
                </div>
                <div v-if="l.type==10" class="intro">
                    <span>{{ l.desc }}</span>
                </div>
                <div v-if="l.type==17" class="intro">
                    <span>Fee:{{ l.desc.split(',')[1]/1000000 }} {{ $t('common.currency') }}</span>
                </div>
            </div>
            <div v-if="showLoadingMore" class="center mg30">
                <a-spin v-if="loading" />
                <span v-else class="loading-more" @click="getLogs()">
                    {{$t('common.more')}}
                </span>
            </div>
        </div>
        <Nav />
    </div>
</template>
<script>
import Logo from '../components/Logo.vue';
import Nav from "../components/Nav.vue";

export default {
    name: "Wallet",
    data() {
        return {
            userinfo: {
                address: '', node_level:0, balances:{bt:0,locked_bt:0,gfd:0}
            },
            showGsil: false,
            showTotalFlow:false,
            showDefi: false,
            noFundPass: false,
            loading: false,
            showLoadingMore:true,
            page:1,
            pageSize:10,
            asc:0,
            list:[],
            usdt_rate:1,
            energyLeft: 0,
        };
    },
    components: { Logo, Nav },
    mounted() {
        // let _this = this;
        // let _obj = setInterval(()=>{
        // if (window.walletAddress && window.swapdContract && window.usdtToken && window.stdToken) {
        //     clearInterval(_obj)
        //     window.swapdContract.utsRate().call().then((res)=>{
        //         // console.log(res);
        //         _this.usdt_rate = res.toNumber()/100;
        //     });
        // }
        // }, 50);
        this.getUserinfo();
        this.getLogs();
    },
    methods: {
        commingSoon(){
            this.$message.info(this.$t('common.commingsoon'));
        },
        getUserinfo() {
            let _this = this
            this.$http.call(this.$http.api.userinfo).then(res=>{
                console.log(res);
                _this.userinfo = res.data.data;
                _this.$store.commit('setUserinfo', res.data.data);

            },res=>{
                // console.log('something goes wrong...');
                console.log(res.response);
                if(res.response.status == '401'){
                    this.$store.commit('setToken','');
                    this.$router.push({name:'login'});
                }
            }).then(()=>{
                
            });
        },
        getLogs(append = true){
            this.loading = true;
            this.$http.call(this.$http.api.clogs,{
                data:{page:this.page,size:this.pageSize,sort:this.asc}
            }).then(res=>{
                // console.log(res);
                this.loading = false;
                let resp = res.data;
                if(resp.code=='200'){
                    this.page++;
                    if(append){
                        this.list = this.list.concat(resp.data);
                    }else{
                        this.list = resp.data;
                    }
                    if(resp.data.length<this.pageSize){
                        this.showLoadingMore = false;
                    }
                }else{
                    this.$message.error(resp.data);
                } 
                
            },res=>{
                console.log('something goes wrong...');
                console.log(res.response);
                if(res.response.status == '401'){
                    this.$store.commit('setToken','');
                    this.$router.push({name:'login'});
                }
            });
        },
        withdrawl(){
            if(!this.userinfo.fund_password){
                this.$store.commit('setBtmBox',true);
                this.$store.commit('setShowSetFundpassBox',true);
            }else{
                this.$store.commit('setBtmBox',true);
                this.$store.commit('setShowWithdrawlBox',true);
            }
        },
        ttransfer(){
            if(!this.userinfo.fund_password){
                this.$store.commit('setBtmBox',true);
                this.$store.commit('setShowSetFundpassBox',true);
            }else{
                this.$store.commit('setBtmBox',true);
                this.$store.commit('setShowTtransferBox',true);
            }
        },
        deposit(){
            if(!this.userinfo.fund_password){
                this.$store.commit('setBtmBox',true);
                this.$store.commit('setShowSetFundpassBox',true);
            }else{
                this.$store.commit('setBtmBox',true);
                this.$store.commit('setShowDepositSwapBox',true);
            }
        },
        goWithdrawls(){
            this.$router.push({name:'withdrawls'});
        },
        goReleaseLogs(){
            this.$router.push({name:'releaseLogs'});
        },
        goDeposit(){
            this.$router.push({name:'deposit'});
        },
        goDefiLock(){
            this.$router.push({name:'defiLock'});
        },
        goProfit(){
            this.$router.push({name: 'depositProfits'});
        },
        goDefiLockProfits(){
            this.$router.push({name: 'defiLockProfits'});
        }
    },
};
</script>
<style lang="less" scoped>
.wallet {
    width:100%;
    display: flex;
    flex-direction: column;
    padding: 0 50px;
    padding-bottom: 168px;
    height: 100%;
    .box{
        padding: 30px;
        margin: 30px 0;
        text-align: left;
        .label{
            color: @primary-color;
            font-weight: 500;
        }        
    }
    .amount{
        // font-size: 20px;
        font-weight: bold;
        padding: 15px 0;
    }
    .total{
        position:relative;
        overflow: hidden;
        .tag{
            position: absolute;
            top:0;
            right:0;
            background: @light-green;
            padding: 2PX 10PX;
            font-size: 0.7rem;
            border-bottom-left-radius: 15PX;
        }
        .label{
            // letter-spacing: -0.24px;
        }
    }
    .balance{
        margin-top: 0;
        .usdt-amount{
            font-size: 14PX;
            padding-bottom: 10PX;
        }
        .withdrawl{
            padding: 0;
            margin-top: 16PX;
            margin-left: -15PX;
            margin-right: -15PX;
            // flex-wrap: nowrap;
            .btn{
                padding: 10PX 18PX;
                flex: 1;
                text-align: center;
                margin: 0 15PX;
                border-radius: 8PX;
                margin-bottom: 15PX;
            }
            .btn-buy{
                background: @dark-green;
                color: #fff;
            }
            .btn-withdrawl{
                background: @light-green;
                border: 1px solid @dark-green;
                color: @dark-green;
            }
            .btn-deposit{
                background: @dark-green;
                color: #fff;
            }
        }
    }
    .gsil {
        .title {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 15px;
            font-weight: bold;
            color: @primary-color;
            img {
                // width: 18px;
                 height: 18px;
                 padding-right: 5px;
            }
        }
        .li {
            padding: 7px 0;
        }
    }
    .defi{
        position:relative;
        overflow: hidden;
        .tag{
            position: absolute;
            top:0;
            right:0;
            background: @light-green;
            padding: 2px 10px;
            font-size: 0.7rem;
            border-bottom-left-radius: 15px;
        }
        .btn{
            padding: 7px 0;
        }
    }
    .list {
        // margin-bottom: 75px;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 0;
        .list-item {
            padding: 10PX 15PX;
            border-bottom: 1px solid #dedede;
            position: relative;
            .time {
                font-size: 12PX;
            }
            .intro {
                position: absolute;
                right: 15PX;
                bottom: 10PX;
                color: gray;
                font-size: 12PX;
            }
        }
        
    }
    .loading-more {
        text-align: center;
        margin: 30PX auto;
        height: 32PX;
        line-height: 32PX;
        padding: 5PX 15PX;
        border: 1PX dashed @primary-color;
        color: @primary-color;
    }
}
</style>
